import React from "react";
import { useForm } from "react-hook-form";
import { SNSClient, PublishCommand } from "@aws-sdk/client-sns"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

export default function ContactForm({ identity, topic, region }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showFailure, setShowFailure] = React.useState(false)
  const succeeded = () => {
    reset({})
    setShowSuccess(true)
    setShowFailure(false)
  }
  const failed = () => {
    setShowSuccess(false)
    setShowFailure(true)
  }
  const onSubmit = (data) => {
    const client = new SNSClient({
      region: region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: region },
        identityPoolId: identity,
      })
    })
    var params = {
      Message: `
Message from: ${data.emailRequired}

------------------

${data.messageOptional}

------------------
`,
      Subject: `Web site enquiry: ${data.subjectRequired}`,
      TopicArn: topic,
    };
    const command = new PublishCommand(params)
    client
      .send(command)
      .then(
        data => {
          succeeded()
        },
        err => {
          console.log(err)
          failed()
        }
      )
  }

  return (
    <div className="contact-form">
      {showSuccess === true && <div className="message success">Message sent successfully</div>}
      {showFailure === true && <div className="message failed">Failed to send message</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="layout-row"><span>Reply-to email:<sup>*</sup></span>
          <input
            name="email"
            defaultValue=""
            className="focus:outline-black px-2"
            {...register("emailRequired", { required: true, pattern: /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/ }) }
          />
        </label>
        { errors.emailRequired?.type === "required" && <div className="text-center text-red-700">Please enter an email</div> }        
        { errors.emailRequired?.type === "pattern" && <div className="text-center text-red-700">Please enter a valid email address</div> }
        <label className="layout-row"><span>Subject:<sup>*</sup></span>
          <input
            name="subject"
            type="text"
            defaultValue=""
            className="focus:outline-black px-2"
            {...register("subjectRequired", { required: true })}
          />
        </label>
        {errors.subjectRequired && <div className="text-center text-red-700">Subject is required</div>}
        
        <label className="layout-column">
          <span>Message:</span>
          <textarea
            name="message"
            className="focus:outline-black px-2"
            defaultValue=""
            {...register("messageOptional")}
          />
        </label>
        <button className="focus:outline-black px-2" type="submit">Send message</button>
      </form>
    </div>
  )
}
