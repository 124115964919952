import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Footer({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            owner
          }
        }
      }
    `
  )
  const year = new Date().getFullYear();
  return (
    <div className="site-footer">
      Web site ©{year} {data.site.siteMetadata.owner}
    </div>
  )
}
