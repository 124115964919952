import * as React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'


export default class MailchimpForm extends React.Component {
  state = {
    email: '',
    firstname: '',
    lastname: '',
    errors: [],
    successes: []
  }
  

  _handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ errors: []})
    this.setState({ successes: []})
    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.firstname,
      LNAME: this.state.lastname
    })
    switch (result.result) {
      case 'error':
        this.setState({ errors: [result.msg]})
        break
      case 'success':
        this.setState(
          { successes: [result.msg], email: '', lastname: '', firstname: ''}
        )
        break
      default:
        this.setState(
          { successes: [], email: '', lastname: '', firstname: ''}
        )
    }
  }

  render () {
    return (
      <div className="contact-form">
        {this.state.errors.length > 0 && <div className="text-center text-red-700 p-2" dangerouslySetInnerHTML={{ __html: this.state.errors[0]}} />}
        <form onSubmit={this._handleSubmit}>
          <label className="layout-row">
            First name:
            <input type="text" value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value})} />
          </label>
          <label className="layout-row">
            Last name:
            <input type="text" value={this.state.lastname} onChange={(e) => this.setState({ lastname: e.target.value})} />
          </label>
          <label className="layout-row">
            Email:
            <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value})} />
          </label>
          {this.state.successes.length > 0 && <div className="text-center text-green-700 p-2"  dangerouslySetInnerHTML={{ __html: this.state.successes[0]}} />}
          <button className="focus:outline-black px-2" type="submit">Subscribe</button>
        </form>
      </div>
    )
  }

}
