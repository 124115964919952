import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


export default function GallerySet({ root, children }) {
  const data = useStaticQuery(graphql`
  query {
    collections: allMarkdownRemark(
      filter: {fields: {slug: {glob: "/series/*"}}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        fields {
          slug
          thumbnails: gallery {
            fields {
              catalog_number
              order
              size
              source
              title
              bluethumb_link
            }
            gatsbyImageData(
              width: 72
              height: 72
              layout: FIXED
              transformOptions: {fit: CONTAIN}
              placeholder: DOMINANT_COLOR
              backgroundColor: "transparent"
            )
          }
        }
        key: id
        frontmatter {
          title
          order
          date
          template
        }
        excerpt
      }
    }
  }
  `)
  return (
    <ol className="gallery-set">
      {data.collections.nodes.map(gallery => (
        <li key={gallery.id}>
          <Link to={gallery.fields.slug} class="text-gray-700">
            <div class="grid grid-cols-3 grid-rows-1">
              <div class="col-span-3">
                 <h2 class="text-center">{ gallery.frontmatter.title }</h2>
              </div>
              <div class="col-span-3 row-start-2 text-center">
                 { gallery.excerpt }
              </div>
              <ul class="col-span-3 row-start-3 row-end-3 flex flex-wrap justify-center lg:max-w-screen-lg">
                {gallery.fields.thumbnails.map((thumb) => (
                  <GatsbyImage image={getImage(thumb)} alt={thumb.fields.title} class="mr-2 mb-2" />
                ))}
              </ul>
            </div>
          </Link>
        </li>
      )
    )}
    </ol>
  )
}
