import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"


export default function MailchimpCampaignList(props) {
  const data = useStaticQuery(graphql`
    query {
      campaigns: allMailchimpCampaign(
        filter: {type: {ne: "rss"}, status: {eq: "sent"}}
        sort: {fields: send_time, order: DESC}
      ) {
        nodes {
          send_time(formatString: "ddd MMM Do, YYYY")
          status
          type
          settings {
            subject_line
            title
          }
          campaignId
          id
          fields {
            slug
            title
          }
        }
      }
    }
  `)
  return (
    <ol className="w-full list-none pl-4 flex flex-wrap gap-2 justify-center" /* style={{columns: "auto 14em"}} */>
      {data.campaigns.nodes.map(newsletter => (
        <li key={newsletter.id} className="mb-2 flex w-32 flex-shrink-1 flex-grow-0 border">
          <Link to={newsletter.fields.slug} className="p-2">
            <span className="font-medium w-42 block">{newsletter.fields.title || newsletter.settings.title}</span>
            <span className="text-xs whitespace-nowrap">{newsletter.send_time}</span>
          </Link>
        </li>
      ))}
    </ol>
  )
}
