import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import Footer from "./footer"
import ContactForm from "./contact-form"
import MailchimpForm from "./mailchimp-form"
import MailchimpCampaignList from "./mailchimp-campaign-list"
import GallerySet from "../components/gallery-set";

const shortcodes = { Link, ContactForm, MailchimpForm, MailchimpCampaignList, GallerySet }

const NavLink = props => (
  <li className="nav-link">
    <Link to={props.to} activeClassName="active-link">{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        primarynav: markdownRemark(fields: {slug: {eq: "/"}}) {
          id
          fields {
            slug
            siblings {
              id
              slug
              title
              order
            }
          }
          frontmatter {
            date
            order
            template
            title
          }
        }
      }
    `
  )
  return (
    <div>
      <header className="site-navigation">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
        <nav className="site-menu">
          {data.primarynav !== null &&
            <ul className="nav-links">
               <NavLink to={data.primarynav.fields.slug}>{data.primarynav.frontmatter.title}</NavLink>
               {data.primarynav.fields.siblings.sort((a, b) => a.order > b.order).map(item => (
                  <NavLink key={item.id} to={item.slug}>{item.title}</NavLink>
               ))}
            </ul>
          }
        </nav>
      </header>
      <div className="layout-grid">
        <main className="layout-grid--main">
          <MDXProvider components={shortcodes}>{children}</MDXProvider>
        </main>
        <div className="layout-grid--footer">
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}
